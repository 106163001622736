.hero-container {
	background-color: #b3a188;
}

.hero-container > div {
	padding: 5% 5%;
	display: flex;
	justify-content: space-between;
}

.hero-container div.background-image {
	width: 50%;
}

.hero-container div.background-image > img {
	width: 70%;
	height: 100%;
	object-fit: contain;
	border-radius: 10px;
}

.hero-container div.hero-intro {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	color: #ffffff;
	text-align: center;
}

div.hero-intro h1 {
	font-size: 4em;
}

div.hero-intro button {
	min-width: 150px;
	min-height: 50px;
	padding: 5% 15%;
	color: #ffffff;
	border: 1px solid #ffffff;
	margin-top: 10px;
}

div.hero-intro button:hover,
div.hero-intro button::after,
div.hero-intro button:active {
	background-color: #ffffff !important;
	border: 1px solid #ffffff !important;
}
