.experience-container {
	padding: 10% 20%;
}

.experience-container div.experience h1 {
	font-size: 84px;
}

.experience-container div.experience-title {
	display: flex;
	justify-content: space-between;
}

.experience-container div.experience-title h6 {
	font-size: 14px;
}

.experience-container div.experience:not(:last-child) {
	margin-bottom: 100px;
}

.experience-container div.active-perception-lab {
	width: 50%;
}

.experience-container div.marketit-experience {
	margin-left: 250px;
}

div.ta3 {
	margin-left: 50px;
}

div.ta4 {
	margin-left: 200px;
}

div.ta5 {
	margin-left: 150px;
}
