.main-container {
	max-width: 100vw;
	min-height: 100vh;
	background: #a39886;
	--grad-1: rgb(51, 50, 128);
	--grad-2: rgb(207, 209, 62);
}

.main-container > .sidebar-area {
	padding: 0 5%;
	display: flex;
}

.main-container > .sidebar-area > div.sidebar {
	display: block;
}

.main-container > hr {
	color: #ffffff;
	width: 90%;
	margin: auto;
}
