.navigation {
	padding: 5% 5% 1% 5%;
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.navigation > .nav-section {
	display: flex;
}

.navigation > .nav-section > a {
	color: inherit;
	color: #ffffff;
	text-decoration: none;
	font-size: 50px;
}

.navigation > .nav-section > a:not(:first-child) {
	margin-left: 20px;
}
