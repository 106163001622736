.sidebar-sticky {
	height: 20%;
	position: -webkit-sticky;
	position: sticky;
	left: 50%;
	top: 80%;
	transform: translate(-50%, -50%);
}

.sidebar-sticky > a {
	color: #ffffff;
}

.sidebar-sticky > a:not(:last-child) {
	margin-bottom: 30px;
}

.sidebar-sticky > hr {
	min-height: 1000px;
	width: 3px;
	margin: 0 auto;
	background-color: #ffffff;
}
