.education-container {
	margin-top: 200px;
	padding: 5% 20%;
	background-color: #b3a188;
}

.education-container > h1 {
	margin-bottom: 20px;
}

.education-container > div.education-wrapper {
	display: flex;
	justify-content: space-between;
}

.education-container > div.education-wrapper:not(:last-child) {
	margin-bottom: 50px;
}
