.contact-container {
	padding: 5% 20%;
}

.contact-container > div {
	text-align: center !important;
	margin-top: 30px;
}

.contact-container > div {
	display: flex;
	justify-content: space-around;
	margin-top: 50px;
}

.contact-container > div > a {
	text-decoration: underline;
	font-size: 4vw;
	font-weight: bolder;
}
