.about-container {
	padding: 0% 20%;
}

.about-container > div {
	display: flex;
}

.about-container > h1 {
	font-size: 5em;
}

.about-container > div > p {
	margin: 0;
	font-size: 20px;
	line-height: 30px;
}

.about-container > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.about-container > div > div > img {
	width: 200px;
	height: 200px;
}
